import _ from "lodash";
import { environment as defaultEnvironment } from './environment.defaults';

export const environment = _.merge({}, defaultEnvironment, {
  environmentName: 'dev',
  production: false,
  log: false,
  assetsStorageUrl: 'https://assets-embark-dev.ehr.com',
  invisibleReCaptchaSiteKey: "6Lc-YuAaAAAAAK-w3lrZOahjzCkqXEM4rD6t5krF",
  withChallengeReCaptchaSiteKey: "6LeHMd4aAAAAAFZu_mYsNB6QBtYLULNnVBN6R45N",
  registrationApiBaseUrl: 'https://embark-dev-gw.ehr.com/api/Registration/',
  // registrationApiBaseUrl: 'https://localhost:44312/api/',
  translationApiBaseUrl: 'https://embark-dev-gw.ehr.com/api/Registration/',
  //translationApiBaseUrl: 'https://localhost:44312/api/',
  configApiUrl: 'https://embarkpro-devint.ehr.com/std-gateway/api/registrationConfig/',
  loginFriendlyBaseUrl: 'https://signin-embark-dev.ehr.com/',
  kbaBaseUrl: 'https://embark-dev-gw.ehr.com/api/kba/',
  loginEmbarkAppFriendlyBaseUrl: '',
  oneTrustConfig: {
    privacyNoticeGuid: 'bbb80b16-980d-443f-94f1-e348aca60056',
    cookieNoticeGuid: 'b8716e4f-acd5-4ffb-9217-eb31a9b3509b',
    termsOfUseGuid: '882e2831-64c6-4ca1-974b-d53591a973fb',
    noticeBundleGuid: 'c20a4882-10af-4ef8-8376-cbffed898dfa',
  },
  pushNotificationsApiBaseUrl: 'https://embarkpro-devint.ehr.com/std-gateway/api/pushnotifications',
  identityVerification:{
    settlementPortalSDKUrl: 'https://idscan-np.mdp.wtwco.com?',
    primaryColor: '#7F35B2',
    baseUrl: 'https://poc.idscan.cloud/',
    cacheExpiry: '3600000',
    journeyDefinitionIdName: 'SingleAuthPOC'
  }
});

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.