import { Routes } from '@angular/router';
import { PageId } from './enums/page-id.enum';

const create404Path = (path: string, pathMatch: any) => {

  return {
    path: path,
    loadChildren: () => import("./components/page-not-found/page-not-found.module").then((module) => module.PageNotFoundModule),
    pathMatch : pathMatch,
    data: { componentName: "PageNotFoundComponent", pageId: PageId.PageNotFound }
  }
}

export const SIGNUP_PATH = "/signup";
export const ROUTES: Routes = [
  create404Path('404', 'full'),
  create404Path(`:clientCountry${SIGNUP_PATH}/404`, 'full'),
  create404Path(':clientCountry/404', 'full'),
  create404Path('login/:ccId/404', 'full'),
  {
    path: 'lookup/code',
    loadChildren: () => import("./components/lookup-code-form/lookup-code-form.module").then((module) => module.LookupCodeFormModule),
    data: { componentName: "LookupCodeFormComponent", pageId: PageId.LookupCode }
  },
  {
    path: 'lookup/instructions',
    loadChildren: () => import("./components/instructions/instructions.module").then((module) => module.InstructionsModule),
    data: { componentName: "InstructionsComponent", pageId: PageId.LookupInstructions }
  },
  {
    path: 'lookup/self-identification',
    loadChildren: () => import("./components/self-identification/self-identification.module").then((module) => module.SelfIdentificationModule),
    data: { componentName: "SelfIdentificationComponent", pageId: PageId.SelfIdentification }
  },
  //uncomment for POC/Local Development purposes - delete after finishing development
  // {
  //   path: 'lookup/identity-verification',
  //   loadChildren: () => import("./components/identity-verification/identity-verification.module").then((module) => module.IdentityVerificationModule),
  //   data: { componentName: "IdentityVerificationComponent", pageId: PageId.IdentityVerification }
  // },
  {
    path: 'login-issue/:ccId',
    loadChildren: () => import("./components/login-issue/login-issue.module").then((module) => module.LoginIssueModule),
    data: { componentName: "LoginIssueComponent", pageId: PageId.LoginIssue }
  },
  {
    path: 'notices/:noticeName/:cultureCode',
    loadChildren: () => import("./components/notices/notices.module").then((module) => module.NoticesModule),
    data: { componentName: "NoticesComponent" }
  },
  {
    path: "user",
    loadChildren: () => import("./pages/user/user.module").then((module) => module.UserModule)
  },
  {
    path: 'user/change-mfa',
    loadChildren: () => import("./components/change-mfa/change-mfa.module").then((module) => module.ChangeMFAModule),
    data: { componentName: "ChangeMfaComponent", pageId: PageId.ChangeMFA }
  }

];
