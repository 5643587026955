import { HasTranslatedContent } from './../models/has-translated-content.model';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';

import { BaseService } from './base.service';
import { ClientCountryLookupVM } from '../contracts/client-country-lookup.vm';
import { environment } from 'src/environments/environment';
import { BrowserContextService } from './browser-context.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService extends BaseService {
  language: string;

  constructor(private http: HttpClient, private browserContext: BrowserContextService) {
    super(environment.translationApiBaseUrl);
  }

  /*
  * Gets the translations for a specific view model by controller/route
  * ie: https://WTWEXRegistration.azurewebsites.net/ClientCountryLookupTranslation
  *
  */
 public GetTranslations<T>(controllerPath: string, vm: T): Observable<T> {
  const language = this.browserContext.GetCultureCode();
  return this.http.get<T>(this.serviceUrl + controllerPath + language).pipe(
    tap(data => { }),
    catchError( err => {
      return this.HandleServiceError(err);
    }));
  }
}
