import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getQueryParam } from 'src/app/helpers/common.helper';
import { v4 as uuidv4 } from 'uuid';
import { LoginType } from '../enums/login-type.enum';
import { PageId } from '../enums/page-id.enum';
import { BrowserUtility } from '../utility/browser.utility';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private clientCountryIdKey = "clientCountryId";
  private userIdKey = "uid";
  private userSessionIdKey = "userSessionId";
  private newUserKey = "newUser";
  private loginTypeKey = "loginType";
  private userRolesKey = "userRoles";
  private browserUtil: BrowserUtility = new BrowserUtility();

  constructor(private route: ActivatedRoute) {
  }

  private _clientCountryId: string;
  public get clientCountryId(): string {
    if (!this._clientCountryId) this.storeClientCountryId();

    return this._clientCountryId;
  }

  public set clientCountryId(value: string) {
    this._clientCountryId = value;
    console.debug("[Registration:CommonService::setClientCountryId] clientCountryId", value);
  }

  public storeClientCountryId(value: string = "") {
    if (value) return this.clientCountryId = value;

    // get id in query params
    let clientCountryId = this.route.snapshot.paramMap.get('ccId') ||
      this.route.snapshot.queryParamMap.get(this.clientCountryIdKey) ||
      getQueryParam(this.clientCountryIdKey);

    // if still none, try getting in sessionStorage
    if (!clientCountryId)
      clientCountryId = this.browserUtil.getFromSessionStorage(this.clientCountryIdKey);
    else
      this.browserUtil.saveToSessionStorage(this.clientCountryIdKey, clientCountryId);

    return this.clientCountryId = clientCountryId || "";
  }

  private _pageId: PageId;
  public get pageId(): PageId {
    return this._pageId;
  }

  public set pageId(value: PageId) {
    this._pageId = value;
    window.pageId = value?.toString();
    console.debug("[Registration:CommonService::setPageId] pageId", value);
  }

  private _userId: string;
  public get userId(): string {
    if (this._userId) return this._userId;

    // get id in query params
    let userId = this.route.snapshot.queryParamMap.get(this.userIdKey) || getQueryParam(this.userIdKey);

    // if still none, try getting in sessionStorage
    if (!userId)
      userId = this.browserUtil.getFromSessionStorage(this.userIdKey);
    else
      this.browserUtil.saveToSessionStorage(this.userIdKey, userId);

    console.debug("[Registration:CommonService::userId]", userId);

    return this._userId = userId;
  }

  private _userSessionId: string;
  public get userSessionId(): string {
    if (this._userSessionId) return this._userSessionId;

    /**
     * first, get id in query params
     *  userSessionId query param maybe available in Legal Notices page
     */
    let userSessionId = this.route.snapshot.queryParamMap.get(this.userSessionIdKey) ||
      getQueryParam(this.userSessionIdKey);

    // if still none, try getting in sessionStorage
    if (!userSessionId)
      userSessionId = this.browserUtil.getFromSessionStorage(this.userSessionIdKey);

    // if still none, generate a new one
    if (!userSessionId)
      userSessionId = uuidv4();

    // then save it in sessionStorage
    this.browserUtil.saveToSessionStorage(this.userSessionIdKey, userSessionId);

    console.debug("[Registration:CommonService::userSessionId]", userSessionId);

    return this._userSessionId = userSessionId;
  }

  private _newUser: string;
  public get newUser(): string {
    if (this._newUser) return this._newUser;

    // 1. get newUser in querystring
    let newUser = getQueryParam(this.newUserKey);

    // if still none, try getting in sessionStorage
    if (!newUser)
      newUser = this.browserUtil.getFromSessionStorage(this.newUserKey);

    if (newUser) {
      this._newUser = newUser;
      this.browserUtil.saveToSessionStorage(this.newUserKey, newUser);
    } else {
      newUser = "true";
    }

    console.debug("[Registration:CommonService::newUser]", newUser);

    return newUser;
  }

  private _loginType: string;
  public get loginType(): string {
    if (this._loginType) return this._loginType;

    // first, get id in query params
    let loginType = getQueryParam(this.loginTypeKey);

    // if still none, try getting in sessionStorage
    if (!loginType)
      loginType = this.browserUtil.getFromSessionStorage(this.loginTypeKey);
    else
      this.browserUtil.saveToSessionStorage(this.loginTypeKey, loginType);

    if (!loginType) loginType = LoginType.Direct

    console.debug("[Registration:CommonService::loginType]", loginType);

    return this._loginType = loginType;
  }

  private _encryptedUserRoles: string;
  public get encryptedUserRoles(): string {
    if (this._encryptedUserRoles) return this._encryptedUserRoles;

    // 1. get uid in querystring
    let userRoles = getQueryParam(this.userRolesKey);

    // try getting in sessionStorage
    if (!userRoles)
      userRoles = this.browserUtil.getFromSessionStorage(this.userRolesKey);
    else
      this.browserUtil.saveToSessionStorage(this.userRolesKey, userRoles);

    console.debug("[Registration:CommonService::encryptedUserRoles]", userRoles);

    return this._encryptedUserRoles = userRoles;
  }

  public getDecryptedUserRoles(): string {
    return this.encryptedUserRoles ? window.atob(this.encryptedUserRoles) : "";
  }

  public clearSessionData(): void {
    console.debug("[Registration:CommonService::clearSessionData]");

    this._clientCountryId = null;
    this._userId = null;
    this._userSessionId = null;
    this._newUser = null;
    this._loginType = null;
    this._encryptedUserRoles = null;

    this.browserUtil.clearSessionStorage([
      this.clientCountryIdKey,
      this.userIdKey,
      this.userSessionIdKey, // this will also cause a renewing of userSessionId
      this.newUserKey,
      this.loginTypeKey,
      this.userRolesKey,
    ]);
  }
}
