/**
 * https://github.com/Napster2210/ngx-spinner
 */
import { Injectable } from '@angular/core';
import { NgxSpinnerService, Spinner } from 'ngx-spinner';

@Injectable()
export default class SpinnerService {
    constructor() { }

    static spinnerService: NgxSpinnerService;

    static initialize(_spinnerService: NgxSpinnerService) {
        this.spinnerService = _spinnerService;
    }

    private static _blockUiElementId = "app-component-fullscreen";
    static blockUI(): void {
        this.show(this._blockUiElementId, { 
            fullScreen: true,
            type: "ball-spin-fade-rotating",
            size: "medium"
         });
    }

    /**
     * 
     * @param {number} delay must be => 0
     */
    private static unblockUIstartTime: number = 0;
    private static unblockUiTimer: any = null;
    static unblockUI(delay: number = 500): void {
        const datenow = Date.now();
        const unblockTime = datenow + delay;
        if (this.unblockUiTimer) {
            if (unblockTime <= this.unblockUIstartTime) return;

            this.unblockUIstartTime = unblockTime;
        } else {
            this.unblockUIstartTime = unblockTime;
        }

        clearTimeout(this.unblockUiTimer);

        this.unblockUiTimer = setTimeout(() => {
            this.hide(this._blockUiElementId);
            this.unblockUiTimer = null;
            // console.info("spinner hidden");
        }, this.unblockUIstartTime - datenow);
    }

    static show(name: string, options: Spinner = {}): void {
        this.spinnerService.show(name, options);
    }

    static hide(name: string): void {
        this.spinnerService.hide(name);
    }
}
