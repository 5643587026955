import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

export class BaseService  {
  serviceUrl: string;
  constructor(url: string) {
    this.serviceUrl = url;
   }

  HandleServiceError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    console.error({error});
    // return an observable with a user-facing error message
    return throwError('Please try again later.');
  }
}
