import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ReCaptchaRequest } from '../models/recaptcha.models';
import { AuthenticationOption, B2CUrlRequest, CascadingPath, SelfIdentificationVM } from '../models/self-identification.models';
import { BaseService } from './base.service';
import { ValidateReCaptchaResponseModel } from '../models/validate-recaptcha-response.model';
@Injectable({
  providedIn: 'root'
})
export class SelfIdentificationService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super(environment.registrationApiBaseUrl);
  }

  public getSelfIdentificationSelection(clientCountryId: number): Observable<AuthenticationOption[] | CascadingPath[]> {
    const requestUrl = this.serviceUrl + 'SelfIdentification/v2/GetSelfIdentificationSelection/' + clientCountryId;
    return this.httpClient.get<AuthenticationOption[] | CascadingPath[]>(requestUrl);
  }

  public getB2CRegistrationUrlV2MultipleIntegrations(request: B2CUrlRequest): Observable<any> {
    let requestUrl = this.serviceUrl + 'SelfIdentification/v2/GetB2CRegistrationUrl';
    return this.httpClient.post<any>(requestUrl, request);
  }

  public validateReCaptcha(recaptchaRequest: ReCaptchaRequest): Observable<ValidateReCaptchaResponseModel> {
    let requestUrl = this.serviceUrl + 'SelfIdentification/v2/ValidateReCaptcha';
    return this.httpClient.post<ValidateReCaptchaResponseModel>(requestUrl, recaptchaRequest);
  }

  public getTranslations(cultureCode: string, clientCountryId: number): Observable<SelfIdentificationVM> {
    const requestUrl = `${this.serviceUrl}SelfIdentificationTranslation/${cultureCode}/${clientCountryId}`;
    return this.httpClient.get<SelfIdentificationVM>(requestUrl);
  }

  public isBetaPolicy(clientCountryId: string): Observable<boolean>{
    const requestUrl = `${this.serviceUrl}SelfIdentification/v2/IsBetaPolicy?clientCountryId=${clientCountryId}`;
    return this.httpClient.get<boolean>(requestUrl);
  }

  public isEmbarkConnect(clientCountryId: string): Observable<boolean>{
    const requestUrl = `${this.serviceUrl}SelfIdentification/v2/IsEmbarkConnect?clientCountryId=${clientCountryId}`;
    return this.httpClient.get<boolean>(requestUrl);
  }
}
