<header>
  <!-- header & branding -->
  <div id="common-header-element"></div>
</header>
<main class="container-fluid signin">
  <div class="card">
    <!-- Body card -->
    <div class="card-body">
      <router-outlet></router-outlet>
    </div>
  </div><!-- End of body card -->
</main>
<footer>
  <div id="common-footer-element"></div>
</footer>
<ngx-spinner type="ball-spin-fade-rotating" name="app-component-fullscreen"></ngx-spinner>
