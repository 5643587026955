export class BrowserUtility {

  saveToSessionStorage(key: string, value: string): void {
    if (window && window.sessionStorage) {
      return window.sessionStorage.setItem(key, value);
    }
  }

  getFromSessionStorage(key: string): string {
    if (window && window.sessionStorage) {
      return window.sessionStorage.getItem(key);
    }
  }

  clearSessionStorage(keys: string[]): void {
    if (window && window.sessionStorage) {
      for (const key of keys) {
        window.sessionStorage.removeItem(key);
      }
    }
  }
}
