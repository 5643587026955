import { Injectable } from "@angular/core";
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class LocalStorageService {
  constructor() {
  }

  public get Storage(): Storage {
    return window.localStorage;
  }

  //#region localStorage native functions
  setItem(key: string, value: string) {
    this.Storage?.setItem(key, value);
  }

  getItem(key: string) {
    return this.Storage?.getItem(key) ?? "";
  }

  removeItem(key: string) {
    this.Storage?.removeItem(key);
  }

  clear() {
    this.Storage.clear();
  }
  //#endregion localStorage native functions
}
