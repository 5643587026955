import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class CustomizationService {
  private headerElementId: string = "common-header-element";
  private wtwLogoAttr: string = "data-wtw-logo-attr";

  constructor() {
  }

  public SetWtwLogoToEmbark(): void {
    const header = $(`#${this.headerElementId}`);
    const wtwLogoAttrData: any = {
      "src": `${window.location.origin}/assets/images/Embark_Masthead_RGB.png`,
      "alt": "Embark Logo",
      "class": "wtw-logo"
    }

    header.attr(this.wtwLogoAttr, JSON.stringify(wtwLogoAttrData));

    //incase header is already rendered
    header.find("#header img.wtw-logo").attr(wtwLogoAttrData);
  }

  public RemoveTraceEmbarkLogo(): void {
    $(`#${this.headerElementId}`).removeAttr(this.wtwLogoAttr);
  }
}