export enum PageId {
  PageNotFound = 100,
  LookupInstructions = 110,
  LoginIssue = 120,
  LookupCode = 130,
  PrivacyNotice = 140,
  CookieNotice = 142,
  TermsOfUse = 144,
  SelfIdentification = 150,
  HomePage = 160,
  IdentityVerification = 170,
  ChangeMFA = 180
}