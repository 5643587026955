declare var $: any;

/**
 * converts string to a specified Enum
 * @param     {T}       enumObj 
 * @param     {string}  value 
 * @returns   {Enum<T>}
 */
export const stringToEnumValue = <T, K extends keyof T>(enumObj: T, value: string): T[keyof T] | undefined => {
  const enumKey = Object.keys(enumObj).filter((k) => {
    return enumObj[k as K].toString() === value;
  })[0] as keyof typeof enumObj;

  return enumObj[enumKey];
}

export const waitForElementToLoad = (elementSelector: string, loadDoneCallback: () => void) => {
  const retryMsDelay = 100;
  const retryCount = 100; // retryMsDelay * 100 = 10 seconds
  let retries = 0;

  const interval = setInterval(() => {
    retries++;

    if ($(elementSelector).length > 0) {
      clearInterval(interval);
      console.debug(`${elementSelector} found after ${retries} retries!`);
      loadDoneCallback();
    }

    if (retries === retryCount) {
      console.warn(`${elementSelector} not found after ${retries} retries...`);
      clearInterval(interval);
    }
  }, retryMsDelay);
}

/**
 * executes callback if conditionCallback returns true
 * @param {callback function} conditionCallback 
 * @param {callback function} callback 
 * 
 * sample usage:
 *      waitUntilTrue(() => { return $("#header-element").length > 0; }, () => { // callback function });
 */
export const waitUntilTrue = (conditionCallback, callback) => {
  const retryMsDelay = 100;
  const retryCount = 60; // retryMsDelay * 100 = 60 seconds
  let retries = 0;

  const interval = setInterval(() => {
    retries++;

    if (!!conditionCallback()) {
      clearInterval(interval);
      console.debug(`[Registration::CommonHelper:waitUntilTrue] conditionCallback passed after ${retries} retries!`);
      callback();
    }

    if (retries === retryCount) {
      console.debug(`[Registration::CommonHelper:waitUntilTrue] conditionCallback didn't pass after ${retries} retries...`);
      clearInterval(interval);
    }
  }, retryMsDelay);
}

export const getQueryParam = (name: string, url = window.location.href): string => {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`, "i"),
    results = regex.exec(url);
  if (!results || !results[2]) return "";

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const addQueryParamsToUrl = (url: string, paramKey: string, paramVal: any): string => {
  return url += (url.split('?')[1] ? '&' : '?') + paramKey + "=" + paramVal;
}