import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import DeviceDetector from 'device-detector-js';
import { filter, map } from 'rxjs/operators';
import { CustomEventType } from 'src/app/enums/custom-event-type.enum';
import { environment } from 'src/environments/environment';
import { BrowserContextService } from '../browser-context.service';
import { CommonService } from '../common.service';

declare global {
  interface Window {
    appInsights: any;
    pageId: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private browserContextService: BrowserContextService) {

    // initialize AppInsights
    this.appInsights;
    this.appInsightsPro;
    
    this.handleRouter();
  }

  private handleRouter(): void {
    let navigationEnd: NavigationEnd;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        navigationEnd = event;
        return this.activatedRoute;
      }),
      map(route => route.firstChild)).subscribe((route) => {
        // this means we are in the root
        if (!route) {
          window.document.body.style.display = "block";
          return;
        }

        const config = route.routeConfig;
        const componentName = config?.data?.componentName;
        this.commonService.pageId = config?.data?.pageId;

        // special case for LoginRedirectComponent and SelfIdentificationComponent
        // since we need to log the clientId, we should do the logging manually
        if (!componentName ||
          (componentName !== 'LoginRedirectComponent' &&
            componentName !== 'SelfIdentificationComponent' &&
            componentName !== 'NoticesComponent')) {
          this.logPageView(`${componentName} ${config.path}`, navigationEnd.urlAfterRedirects);
        }

        if (componentName !== 'LoginRedirectComponent') {
          window.document.body.style.display = "block";
        }
      });
  }

  public logPageView(name?: string, uri?: string, properties?: { [key: string]: string }) {
    if (!this.appInsightsPro) return;

    const tabPath = `${window.location.pathname}${window.location.search}`;
    properties = {
      ...properties,

      PortalId: this.measurements.PortalId.toString(),
      IsNewUser: this.commonService.newUser,
      LoginType: this.commonService.loginType,
      PageId: this.commonService.pageId?.toString() ?? window.pageId ?? "",
      TabPath: (tabPath || "").substring(0, 255) // 255 is the limit set by Embark Pro
    }

    const measurements = this.measurements;
    this.appInsightsPro.trackPageView({ name, uri, properties, measurements });
    console.debug("[Registration:LoggingService::logPageView]", { name, uri, properties, measurements });
  }

  public logEvent(name: CustomEventType | string, cardTitle: string, contentArea: string, properties?: { [key: string]: any }) {
    if (!this.appInsightsPro) return;

    properties = {
      ...properties,

      PortalId: this.measurements.PortalId.toString(),
      "Card title": cardTitle,
      "Card type": "1",
      ContentArea: contentArea
    }

    const measurements = this.measurements;
    this.appInsightsPro.trackEvent({ name, measurements }, properties);
    console.debug("[Registration:LoggingService::logEvent] data=%o properties=%o", { name, measurements }, properties);
  }

  public logException(exception: Error, severityLevel?: number) {
    this.appInsights?.trackException({ exception: exception, severityLevel: severityLevel });
    console.debug("[Registration:LoggingService::logException] exception", exception);
  }

  public logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights?.trackTrace({ message: message }, properties);
    console.debug("[Registration:LoggingService::logTrace] message", message);
  }

  /**
   * isTelemetryEnabled
   * true => enable tracking
   * false => disable tracking
   */
   private get isTelemetryEnabled(): boolean {
      const telemetryCookieValue = this.tryGetCookieValue('telemetry-cookies-disabled');
      if (telemetryCookieValue != null) {
          return telemetryCookieValue !== 'true'
      }
    
      const optanonCookieValue = this.tryGetCookieValue('OptanonConsent');
      const groups = optanonCookieValue?.split('&').filter(p => p.includes('groups'));
      if (groups != null) {
          const c0002Value = groups[0].charAt(groups[0].length - 1);
          return c0002Value !== '0'
      }
    
      return false;
   }

  private tryGetCookieValue(name: string): string | null {
    const result = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^\\s;]+)');
    return result ? result.pop() : null;
  }

  private _cultureCode: string;
  private get cultureCode(): string {
    if (this._cultureCode) return this._cultureCode;
    return this._cultureCode = this.browserContextService.GetCultureCode();
  }

  private _appInsights: ApplicationInsights;
  private get appInsights(): ApplicationInsights {
    if (!this.isTelemetryEnabled) return null;
    if (this._appInsights) return this._appInsights;

    let appInsights = this.initializeAppInsights(environment.appInsightsConfig);

    // store in a global variable so [Assets] can pick it up and reuse it
    window["appInsights"] = appInsights;

    return this._appInsights = appInsights;
  }

  private _appInsightsPro: ApplicationInsights;
  private get appInsightsPro(): ApplicationInsights {
    if (!this.isTelemetryEnabled) return null;
    if (this._appInsightsPro) return this._appInsightsPro;

    const config = {
      disableExceptionTracking: true,
      disableAjaxTracking: true,
      disableCorrelationHeaders: true,
      enableUnhandledPromiseRejectionTracking: false,
      enableAjaxErrorStatusText: false,
      loggingLevelTelemetry: 0,

      ...environment.appInsightsConfigPro,
    }

    let appInsightsPro = this.initializeAppInsights(config);

    // store in a global variable so [Assets] can pick it up and reuse it
    window["appInsightsPro"] = appInsightsPro;

    return this._appInsightsPro = appInsightsPro;
  }

  private initializeAppInsights(config: any): ApplicationInsights {
    const ai = new ApplicationInsights({ config });
    ai.loadAppInsights();

    if (this.commonService.userId) ai.setAuthenticatedUserContext(this.commonService.userId);

    if (ai.addTelemetryInitializer) {
      ai.addTelemetryInitializer((envelope: any) => {
        // override DeviceType
        envelope.tags["ai.device.type"] = this.deviceType;

        // override SessionId
        envelope.tags["ai.session.id"] = this.commonService.userSessionId;

        // set Default Custom Dimensions
        envelope.data = {
          ...(envelope.data || {}),
          // AppName: environment.appInsightsConfig.applicationName,
          // AppVersion: environment.appInsightsConfig.version,
          PortalEnvironment: config.environmentName,
          ContentGroupName: this.cultureCode,
          UserRoles: this.commonService.getDecryptedUserRoles() || `{"UserRoles":[]}`,
          IsGuestUser: "false"
        }
      });
    }

    return ai;
  }

  private _deviceType: string;
  private get deviceType(): string {
    if (this._deviceType) return this._deviceType;

    const userAgent = navigator.userAgent;
    const deviceInfo = new DeviceDetector().parse(userAgent);
    console.debug("[Registration:LoggingService::deviceType] deviceInfo", deviceInfo);

    let type: string;
    switch (deviceInfo.device.type.toString()) {
      case "desktop":
        type = "Desktop";
        break;
      case "smartphone":
        type = "Mobile";
        break;
      case "tablet":
        type = "Tablet";
        break;
      case "": // some cases in Safari browser returns null/"", we do fallback checking
        switch (true) {
          case /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent):
            type = "Mobile";
            break;
          case /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent.toLowerCase()):
            type = "Tablet";
            break;
          default:
            type = "Others";
            break;
        }
        break;
      default:
        type = "Others";
    }

    return this._deviceType = type;
  }

  private getPortalId(): number {
    const clientCountryIdInt = this.commonService.clientCountryId ? parseInt(this.commonService.clientCountryId) : 0;
    return 100000 + clientCountryIdInt;
  }

  private get measurements(): any {
    return {
      PortalId: this.getPortalId(),
    };
  }
}
