import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {
  constructor(private injector: Injector) { }

  public async init(): Promise<void> {
    console.debug("[AppInitializerService::init]");
    // await this.hideWhenInLoginRedirectPage();
  }

  private async hideWhenInLoginRedirectPage(): Promise<void> {
    console.debug("[AppInitializerService::hideWhenInLoginRedirectPage]");
    window.document.body.style.display = "none";

    // const router = this.injector.get(Router);
    // const activatedRoute = this.injector.get(ActivatedRoute);
    // const config = activatedRoute.routeConfig;
    // const componentName = config?.data?.componentName;

    // if (componentName === 'LoginRedirectComponent') {
    //   window.document.body.style.display = "none";
    // }
  }
}
