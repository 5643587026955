// environment.defaults.ts
export const environment = {
  environmentName: '',
  production: false,
  log: true,
  flags: {
    useNewHeader: true
  },
  MaxNoOfSpamAttempt: 5,
  assetsStorageUrl: 'https://assets-embark-qa.ehr.com',
  invisibleReCaptchaSiteKey: "6Lc-YuAaAAAAAK-w3lrZOahjzCkqXEM4rD6t5krF",
  withChallengeReCaptchaSiteKey: "6LeHMd4aAAAAAFZu_mYsNB6QBtYLULNnVBN6R45N",
  registrationApiBaseUrl: 'https://wtw-ex-appx-registration-api-qa.azurewebsites.net/api/',
  b2cRegistrationApiBaseUrl: 'https://n21projectexb2cextdevappsvc.azurewebsites.net/api/',
  translationApiBaseUrl: 'https://wtw-ex-appx-registration-api-qa.azurewebsites.net/api/',
  configApiUrl: 'https://wtwexwidgetsconfigurationapiqa.azurewebsites.net/api/',
  loginFriendlyBaseUrl: 'https://n21pexregistrationuidevappsvc.azurewebsites.net/',
  kbaBaseUrl: 'https://n21pexkbaapiqaappsvc.azurewebsites.net/api/',
  loginEmbarkAppFriendlyBaseUrl: '',
  urlPrefixExternal: 'https://',
  appInsightsConfig: {
    applicationName: 'WTW.EX.Registration',
    version: '1.0.8',
    instrumentationKey: '6840040d-f64b-457a-8a5a-7eb3d6c35c5e',
    environmentName: 'dev',
  },
  appInsightsConfigPro: {
    applicationName: 'WTW.EX.Registration',
    version: '1.0.8',
    instrumentationKey: '6840040d-f64b-457a-8a5a-7eb3d6c35c5e',
    environmentName: 'dev',
  },
  defaultLanguage: 'en-us',
  iosNativeMessage: 'notifyMobile',
  oneTrustConfig: {
    scriptSource: '',
    scriptId: '',
    defaultLang: '',
    privacyNoticeGuid: '',
    cookieNoticeGuid: '',
    termsOfUseGuid: '',
    noticeBundleGuid: '',
    noticeIsDraft: false
  },
  pushNotificationsApiBaseUrl: 'https://n21pexpushnotificationsqaappsvc.azurewebsites.net/api',
  supportEmail: "embarksupport@wtwco.com",
  emailSender: "noreply@willistowerswatson.com",
  b2cLinkExpiryMinutes: 10,
  userProfileBaseURL: 'https://n21pexuserprofileqaappsvc.azurewebsites.net/api',
  redirectUserProfileEmbark: 'https://embark-qa.ehr.com/user-profile',
  policyList: {
    editPhoneNumberPolicy: 'B2C_1A_PEX_ChangePhoneNumber',
    changeMFAPolicy:'B2C_1A_PEX_ChangeMFA'
  },
  identityVerification:{
    settlementPortalSDKUrl: 'https://idscan.mdp.wtwco.com?',
    primaryColor: '#7F35B2',
    baseUrl: 'https://poc.idscan.cloud/',
    cacheExpiry: '3600000',
    journeyDefinitionIdName: 'SingleAuthPOC'
  }
};
