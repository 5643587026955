import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { AppInitializerService } from './service/app-initializer.service';
import { BrowserContextService } from './service/browser-context.service';
import { ClientCountryService } from './service/client-country.service';
import { CommonService } from './service/common.service';
import { CustomizationService } from './service/customization.service';
import { DownStreamAppMembersService } from './service/down-stream-app-members.service';
import { LocalStorageService } from './service/local-storage.service';
import { ErrorHandlerService } from './service/logging/error.handler.service';
import { LoggingService } from './service/logging/logging.service';
import { SelfIdentificationService } from './service/self-identification.service';
import { SessionStorageService } from './service/session-storage.service';
import { TranslationService } from './service/translation.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    RouterModule.forRoot(ROUTES, {
      enableTracing: !environment.production
    }),
    NgbModule,
    NgxSpinnerModule,
  ],
  providers: [
    { provide: TranslationService, useClass: TranslationService },
    { provide: BrowserContextService, useClass: BrowserContextService },
    { provide: ClientCountryService, useClass: ClientCountryService },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    Title,
    SessionStorageService,
    LocalStorageService,
    CustomizationService,
    LoggingService,
    DownStreamAppMembersService,
    SelfIdentificationService,
    CommonService,
    NgxSpinnerService,
    AppInitializerService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appUpdateService: AppInitializerService) => () => appUpdateService.init(),
      deps: [AppInitializerService],
      multi: true
    },
  ],
  bootstrap: [AppComponent],
  schemas: [],
})
export class AppModule {
  constructor() { }
}
