import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { ClientIdResponse, ValidateMemberRequest, ValidateMemberResponse } from '../models/self-identification.models';

@Injectable({
  providedIn: 'root'
})
export class DownStreamAppMembersService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(`${environment.registrationApiBaseUrl}`);
  }

  public getClientId(clientCountryId: number): Observable<string> {
    return this.httpClient.get<ClientIdResponse>(`${this.serviceUrl}Members/v2/GetClientId/${clientCountryId}`).pipe(
      map((result: ClientIdResponse) => {
        console.debug("getClientId", result);
        return result.clientId;
      }),
      catchError(this.HandleServiceError));
  }

  // public validateMember(request: ValidateMemberRequest): Observable<ValidateMemberResponse> {
  //   return this.httpClient.post<ValidateMemberResponse>(`${this.serviceUrl}Members/ValidateMember`, request).pipe(
  //     map((result: ValidateMemberResponse) => {
  //       console.debug("validateMember", result);
  //       return result;
  //     }),
  //     catchError(this.HandleServiceError));
  // }

  public validateMemberMultipleIntegrations(request: ValidateMemberRequest): Observable<ValidateMemberResponse[]> {
    return this.httpClient.post<ValidateMemberResponse[]>(`${this.serviceUrl}Members/v2/ValidateMemberAll`, request).pipe(
      map((result: ValidateMemberResponse[]) => {
        console.debug("validateMemberMultipleIntegrations", result);
        return result;
      }),
      catchError(this.HandleServiceError));
  }
}


