import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { BrowserUtility } from '../utility/browser.utility';
import { BrowserContextService } from './browser-context.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  browserUtil: BrowserUtility = new BrowserUtility();

  public get Storage(): Storage {
    return window.sessionStorage;
  }

  constructor(private browserContextService: BrowserContextService) {
  }

  public ClearClientCountry(): void {
    this.browserUtil.clearSessionStorage([this.browserContextService.clientCountryCodeKey]);
    $("#common-header-element #header .brand-logo").closest("li").remove();
  }

  //#region sessionStorage native functions
  setItem(key: string, value: string) {
    this.Storage?.setItem(key, value);
  }

  getItem(key: string) {
    return this.Storage?.getItem(key) ?? "";
  }

  removeItem(key: string) {
    this.Storage?.removeItem(key);
  }

  clear() {
    this.Storage.clear();
  }
  //#endregion sessionStorage native functions
}