import { Component, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PageId } from './enums/page-id.enum';
import { CommonService } from './service/common.service';
import { LoggingService } from './service/logging/logging.service';
import { BaseComponent } from './shared/base-component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AppComponent extends BaseComponent {

  constructor(
    private loggingService: LoggingService, 
    private commonService: CommonService,
    private spinnerService: NgxSpinnerService) {
    commonService.pageId = PageId.HomePage;
    super();
    
    // custom services/helpers
    this.spinner.initialize(spinnerService);

    // console.debug(`[Registration:AppComponent::constructor] window.sessionStorage.getItem("userSessionId")`, window.sessionStorage.getItem("userSessionId"))
    // commonService.renewUserSessionId();
    // console.debug(`[Registration:AppComponent::constructor] userSessionId after renew`, window.sessionStorage.getItem("userSessionId"))
  }

}
