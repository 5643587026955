import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ClientCountryDTO, ClientCountryLanguageDTO, LoginSettingsDTO } from '../contracts/client-definitions';
import { SdkConfig } from '../contracts/native/pre-auth-sdk-config';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ClientCountryService extends BaseService {
  private countryClientServiceURL = 'ManageClients/';
  private loginSettingsURL = 'LoginSettings/';

  constructor(private http: HttpClient) {
    super(environment.configApiUrl);
  }

  public getClientCountryByURL(url: string) {
    return this.http.get<ClientCountryDTO>(this.serviceUrl + this.countryClientServiceURL + 'GetClientCountryByURL?urlName=' + url);
  }

  public getClientCountryById(clientCountryId: number): Observable<ClientCountryDTO> {
    return this.http.get<ClientCountryDTO>(this.serviceUrl + this.countryClientServiceURL + 'GetClientCountryById?clientCountryId=' + clientCountryId);
  }

  public getClientCountryLanguages(id: number) {
    return this.http.get<ClientCountryLanguageDTO[]>(this.serviceUrl + this.countryClientServiceURL + 'GetClientCountryLanguages/' + id);
  }

  public getLoginSettings(clientCountryId: number) {
    return this.http.get<LoginSettingsDTO>(this.serviceUrl + this.loginSettingsURL + 'GetLoginSettings?clientCountryId=' + clientCountryId);
  }

  public checkLookupCode(code: string): Observable<ClientCountryDTO> {
    return this.http.get<ClientCountryDTO>(this.serviceUrl + this.countryClientServiceURL + 'GetClientCountryByLookupCode/' + code).pipe(
      tap(
        data => { }),
      catchError(this.HandleServiceError)
    );
  }

  getSdkConfig(clientCountryId: string): Observable<SdkConfig> {
    return this.http.get<SdkConfig>(`${environment.pushNotificationsApiBaseUrl}/Settings/SdkConfig?clientCountryId=${clientCountryId}`);
  }
}
